body, html {
  height: 100%;
}

a, a:hover {
  color: white;
  text-decoration: none;
}

.logo-text {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
}

.fixnav {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: inherit !important;
}

.App {
  text-align: center;
}

.App-logo {
  /* height: 30vmin;*/
  pointer-events: none;
}
.App-logo::selection { color: transparent; }

.vh30 {
  height: 30vmin;
}
.vh35 {
  height: 35vmin;
}
.vh40 {
  height: 40vmin;
}
.vh50 {
  height: 50vmin;
}
.vh60 {
  height: 60vmin;
}
.vh100 {
  height: 100vmin !important;
}

.bg-golden {
  background-color: #FC4 !important;
}

#tendies {
  margin-top: 1em;
  margin-bottom: 1em;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-mode {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.dark-mode {
    background-color: #282c34;
    color: white;
    min-height: 100vh;

}

.input-group {
  margin: .5rem;
}

.darkotron {
  background-color: #333 !important;
  color: white;
}

.chart {
  height: 30em;
}

.outlined {
  background-color: #343a40;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: .5em;
  border: 1px solid #222;
  border-radius: 5px;
}

.outlined .row {
  margin-bottom: .5em;
}

article {
  border-radius: 5px;
}

.chart article { height: 80vh !important;}

#root h1, #root h2, #root h3, #root h4, #root h5, #root h6, #root p {
  font-family: 'Noto Sans JP', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;700&display=swap');

.navbar a, .navbar:visited, .navbar:hover{
  color: white;
  text-decoration: none !important;
}

.navbar button {
  margin: 0 .2em;
}

#symbol {
    text-transform: uppercase;
}